import React, { useRef } from 'react'
import Header from '../Header'
import { useState, useEffect } from 'react';
import { default as Web3 } from "web3";
import Footer from '../Footer';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import succesImage from '../../assets/images/success.png'
import AuthService from '../../api/services/HomeService';
import copy from "copy-to-clipboard"; 

const QuickBuy = () => {
    const [depositOption, setdepositOption] = useState();
    const [amount, setamount] = useState();
    const [token, settoken] = useState();
    const [walletAddress, setwalletAddress] = useState();
    const [email, setemail] = useState();

    const [transNumber, settransNumber] = useState('');
    const [transImage, settransImage] = useState();

    const [bankName, setbankName] = useState('');
    const [accHoldName, setaccHoldName] = useState('');
    const [accNumber, setaccNumber] = useState('');
    const [ifscCode, setifscCode] = useState('');
    const [bankStatus, setbankStatus] = useState();

    const [upiId, setupiId] = useState('multi@ybl');
    const [upiStatus, setupiStatus] = useState();

    const [showScreen, setshowScreen] = useState('mainScreen');

    useEffect(() => {
        getbankdetails()
        getupidetails()

    }, []);
    useEffect(() => {
        window.scrollTo({ top: 20, behavior: 'smooth' });
        const links = document.querySelectorAll('.nav-link');
        links.forEach((link) => {
          link.classList.remove('active');
        })
      
    }, []);
    const buyCoin = async (depositOption, amount, token, walletAddress, email, transNumber, transImage) => {
        LoaderHelper.loaderStatus(true);
        var formData = new FormData();
        formData.append('Deposit_option', depositOption);
        formData.append('inramount', amount);
        formData.append('tokenamount', token);
        formData.append('walletaddress', walletAddress);
        formData.append('emailId', email);
        formData.append('referencenumber', transNumber);
        formData.append('issueImage', transImage);

        await AuthService.wallet(formData).then((result) => {
            if (result?.status) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage('Order Successfull');
                    setshowScreen('successPage');
                } catch {
                    console.log(result?.msg);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message);
            }
        })
    };
    const getbankdetails = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getbankdetails().then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setaccNumber(result?.data?.AccNumber);
                setbankName(result?.data?.BankName)
                setifscCode(result?.data?.IFSCcode)
                setaccHoldName(result?.data?.Name)
                setbankStatus(result?.data?.status)
                // alertSuccessMessage('Bank Successfully Added')
                try {
                } catch (error) {
                    console.log(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }
    const getupidetails = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getupidetails().then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setupiId(result?.data?.UPI_Id)
                    setupiStatus(result?.data?.status)
                } catch (error) {
                    console.log(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }
    const handleDetails = async () => {
        depositOption && amount && token && walletAddress && email ?
            setshowScreen(depositOption === 'NEFT' ? 'bankDetails' : 'upiDetail') : alertErrorMessage('Please Fill All Required Details')
    }
    const handleInput = async (e) => {
        switch (e.target.name) {
            case 'setdepositOption':
                setdepositOption(e.target.value)
                break;

            case 'amount':
                settoken(+e.target.value / 50)
                setamount(e.target.value)
         
                break;

            case 'token':
                settoken(e.target.value)
                setamount(e.target.value * 50)

          
                break;

            case 'addres':
                setwalletAddress(e.target.value)
                break;

            case 'Email':
                setemail(e.target.value)
                break;

            case 'transNumber':
                if (+e.target.value.length > 12) {
                    alertErrorMessage('Transaction Number should not be Greater than 12')
                } else {
                    settransNumber(e.target.value)
                }
                break;

            default:
                break;
        }
    }

        return (
        <>

            {/* Main Screen */}
            <section class="  section-padding   login_sec" style={{ display: showScreen === 'mainScreen' ? '' : 'none' }}>
                <div class="section-overlay"></div>

                <div class="container">
                    <div class="row">

                        <div class="col-lg-6 col-12 mx-auto">
                            <div class="page_title d-block text-center mb-5" >
                                <h3 class="" >  Buy <strong class="txt_gradient" > METAi  </strong> Token</h3>
                                <p class="h6">INR Payment</p>
                            </div>
                            <form class="custom-form    ticket-form mb-5 mb-lg-0" action="#" method="post" role="form">

                                <div class="cfield_radio">
                                    <label class="mb-1" >Select Deposit Options <em class="text-danger">*</em></label>
                                    <div class="radio_box">
                                        <label>
                                            <input type="radio" name="setdepositOption" value='NEFT' onChange={(e) => { handleInput(e) }} /><span><em>NEFT</em></span></label>
                                        <label>
                                            <input type="radio" name="setdepositOption" value='UPI' onChange={(e) => { handleInput(e) }} /><span><em>UPI</em></span></label>
                                    </div>
                                </div>

                                <div class=" cfield_group">
                                    <label class="mb-1">Enter Amount INR <em class="text-danger">*</em></label>
                                    <div class="cfield mb-3">
                                        <input type="number" class="form-control" placeholder="Enter Amount INR" name="amount" id="amount" value={amount} onChange={(e) => { handleInput(e) }} />
                                    </div>
                                </div>

                                <div class="price_bar " >
                                    Min Limit: INR 500 | Max Limit: INR {depositOption === 'UPI' ? '99,000' : '5,000,00'}  Per Transaction
                                </div>


                                <div class=" cfield_group">
                                    <label class="mb-1">Enter Token <em class="text-danger">*</em></label>
                                    <div class="cfield mb-0">
                                        <input type="number" class="form-control" placeholder="Enter Token " name="token" id="token" value={token} onChange={(e) => { handleInput(e) }} />
                                    </div>
                                </div>
                                <div class="price_bar " >
                                    Price:-INR 50
                                </div>
                                <div class="cfield">
                                    <label class="mb-1">Enter Wallet Address <em class="text-danger">*</em></label>

                                    <input type="text" class="form-control" placeholder="Enter Recieving Wallet Address " name="addres" id="address" value={walletAddress} onChange={(e) => { handleInput(e) }} />
                                </div>
                                <div class="cfield">
                                    <label class="mb-1">Enter Email <em class="text-danger">*</em></label>

                                    <input type="text" class="form-control" placeholder="Enter Your Email" name="Email" value={email} onChange={(e) => { handleInput(e) }} />
                                </div>

                                <button type="button" class=" btn custom-btn btn-gradient btn-border  btn-xl w-100 " onClick={() => handleDetails()}> Continue </button>

                            </form>
                        </div>
                    </div>
                </div>
            </section>


            {/* Bank Details */}
            <section class="  section-padding   login_sec" style={{ display: showScreen === 'bankDetails' ? '' : 'none' }}>
                <div class="section-overlay"></div>

                <div class="container">
                    <div class="row">

                        <div class="col-lg-6 col-12 mx-auto">
                            <div class="page_title d-block text-center mb-5" >
                                <h3 class="" >  Buy <strong class="txt_gradient" > METAi  </strong> Token</h3>
                                <p class="h6">INR Payment</p>
                            </div>
                            {bankStatus === 'Active' ?
                                <form class="custom-form    ticket-form mb-5 mb-lg-0" >


                                    <div class=" cfield_group">
                                        <label class="mb-1">Bank Name
                                            <em class="text-danger">*</em></label>
                                        <div class=" input-group">
                                            <input type="text" class="form-control text-dark" disabled name="amount" id="amount" value={bankName} />
                                            <button type="button" class="pt-2 btn btn-icon" onClick={() => {
                                                copy(bankName); alertSuccessMessage('Copied!!')
                                            }}><i class="ri-file-copy-line"></i></button>
                                        </div>
                                    </div>
                                    <div class=" cfield_group">
                                        <label class="mb-1">Account Holder Name
                                            <em class="text-danger">*</em></label>
                                        <div class=" input-group">
                                            <input type="text" class="form-control text-dark" disabled name="amount" id="amount" value={accHoldName} />
                                            <button type="button" class="pt-2 btn btn-icon" onClick={() => {
                                                copy(accHoldName); alertSuccessMessage('Copied!!')
                                            }}><i class="ri-file-copy-line"></i></button>
                                        </div>
                                    </div>
                                    <div class=" cfield_group">
                                        <label class="mb-1">Account Number
                                            <em class="text-danger">*</em></label>
                                        <div class=" input-group">
                                            <input type="text" class="form-control text-dark" disabled name="amount" id="amount" value={accNumber} />
                                            <button type="button" class="pt-2 btn btn-icon"onClick={() => {
                                                copy(accNumber); alertSuccessMessage('Copied!!')
                                            }}><i class="ri-file-copy-line"></i></button>
                                        </div>
                                    </div>
                                    <div class=" cfield_group">
                                        <label class="mb-1">IFSC Code

                                            <em class="text-danger">*</em></label>
                                        <div class=" input-group">
                                            <input type="text" class="form-control text-dark" disabled name="amount" id="amount" value={ifscCode} />
                                            <button type="button" class="pt-2 btn btn-icon" onClick={() => {
                                                copy(ifscCode); alertSuccessMessage('Copied!!')
                                            }}><i class="ri-file-copy-line"></i></button>
                                        </div>
                                    </div>


                                    <button type="button" class=" btn custom-btn btn-gradient btn-border  btn-xl w-100 " onClick={() => { setshowScreen('submitDetails') }}  > Continue </button>

                                </form>

                                : <form class="custom-form    ticket-form mb-5 mb-lg-0" ><p className='text-danger text-center' style={{ fontWeight: 'bold' }}>Orders using NEFT are Temporary Closed!!</p>    </form>}
                        </div>
                    </div>
                </div>
            </section>



            {/* UPI Details */}
            <section class="  section-padding   login_sec" style={{ display: showScreen === 'upiDetail' ? '' : 'none' }}>


                <div class="container">
                    <div class="row">

                        <div class="col-lg-6 col-12 mx-auto">

                            <div class="page_title d-block text-center mb-5" >
                                <h3 class="" >  Buy <strong class="txt_gradient" > METAi  </strong> Token</h3>
                                <p class="h6">INR Payment</p>
                            </div>
                            {upiStatus === 'Active' ?
                                <form class="custom-form    ticket-form mb-5 mb-lg-0" action="#" method="post" role="form">

                                    <div class=" cfield_group">

                                        <label class="mb-1">UPI ID
                                            <em class="text-danger">*</em></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control text-dark" disabled name="amount" id="amount" value={upiId}    />
                                            <button className='pt-2 btn btn-icon' type='button' 
                                            onClick={() => {
                                                copy(upiId); alertSuccessMessage('Copied!!')         
                                            }}
                                                ><i class="ri-file-copy-line"></i> </button>
                                        </div>
                                    </div>





                                    <button type="button" class=" btn custom-btn btn-gradient btn-border  btn-xl w-100 " onClick={() => { setshowScreen('submitDetails') }} > Continue </button>

                                </form> : <form class="custom-form ticket-form mb-5 mb-lg-0 " ><p className='text-danger text-center font-weight-bold' style={{ fontWeight: 'bold' }}>Orders using UPI are Temporary Closed!!</p> </form>}
                        </div>
                    </div>
                </div>
            </section>


            {/* Submit Page*/}
            <section class="  section-padding   login_sec" style={{ display: showScreen === 'submitDetails' ? '' : 'none' }}>
                <div class="section-overlay"></div>

                <div class="container">
                    <div class="row">

                        <div class="col-lg-6 col-12 mx-auto">
                            <div class="page_title d-block text-center mb-5" >
                                <h3 class="" >  Buy <strong class="txt_gradient" > METAi  </strong> Token</h3>
                                <p class="h6">INR Payment</p>
                            </div>
                            <form class="custom-form    ticket-form mb-5 mb-lg-0" action="#" method="post" role="form">


                                <div class=" cfield_group">
                                    <label class="mb-1">Enter 12-Digit Transaction No.
                                        <em class="text-danger">*</em></label>
                                    <div class="cfield mb-3">
                                        <input type="number" class="form-control " placeholder='Enter 12-Digit Transaction No. ' name="transNumber" id="amount" onChange={(e) => { handleInput(e) }} value={transNumber} />
                                    </div>
                                </div>
                                <div class=" cfield_group">
                                    <label class="mb-1">Enter Transaction Image
                                        <em class="text-danger">*</em></label>
                                    <div class="cfield mb-3">
                                        <input type="file" class="form-control " name="amount" id="amount" onChange={(e) => { settransImage(e.target.files[0]) }} />
                                    </div>
                                </div>




                                <button type="button" class=" btn custom-btn btn-gradient btn-border  btn-xl w-100 " disabled={+transNumber?.length == 12 ? false : true} onClick={() => { transImage && transNumber ? buyCoin(depositOption, amount, token, walletAddress, email, transNumber, transImage) : alertErrorMessage('Please Fill All Required Details') }} > Submit Details </button>


                            </form>
                        </div>
                    </div>
                </div>
            </section>

            {/* Success Page*/}
            <section class="  section-padding   login_sec"
                style={{ display: showScreen === 'successPage' ? '' : 'none' }}
            >


                <div className='container' >
                    <div class="row">

                        <div class="col-lg-6 col-12 mx-auto">
                            <div class="page_title d-block  mb-5" >
                            </div>
                            <div class="custom-form    ticket-form mb-5 mb-lg-0">

                                <div class="step4 account-popup">
                                    <div class="thankyou">
                                        <div class="">
                                            <div className='section-title mb-3 mb-md-4 text-center' >
                                                <img src={succesImage} />
                                                <h3>Thank you for Purchasing <br />
                                                    <em className='txt_gradient' >METAi</em>
                                                </h3>
                                            </div>
                                            <form class="amount  ">

                                                <div class=" cfield_group ">
                                                    <label class="mb-1">Transaction Id
                                                    </label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control text-dark" disabled 
                                                        name="amount" id="amount" value={transNumber} />
                                                        <button className='pt-2 btn btn-icon' type='button'   onClick={() => {
                                                copy(transNumber); alertSuccessMessage('Copied!!')         
                                            }}><i class="ri-file-copy-line"></i> </button>
                                                    </div>
                                                </div>
                                                <div class="text-left  text-danger  ">
                                                    <small><small>Note: If your order is Placed between 8AM to 6PM it will process within 4 hours same day. Otherwise, it will process next working day.</small></small>
                                                </div>
                                                <div class="what text-left "  >
                                                    <span>What Next :</span>
                                                    <div class="whh  mt-2" >Once your payment is verified your order will process and coin will send to your provided wallet address.</div>
                                                    <div class="whh mt-2">You will receive a confirmation email Once METAi send to your wallet.</div>
                                                    <div class="whh mt-2">If you find any issue please  send email: contact@METAi.com.</div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>






                </div>
            </section>

        </>
    )
}

export default QuickBuy
