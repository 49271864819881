import React from 'react'
import Header from '../Header'
import { useState } from 'react';
import { default as Web3 } from "web3";
import Footer from '../Footer';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';

const ClaimFree = () => {

    const [amount, setamount] = useState();
    const [token, settoken] = useState();

    //******* */ Wallet Connect Functionality*********

    const { ethereum } = window;
    const buy_abi =[];
    const contractAddress = " ";
    const web3 = new Web3(window.ethereum);

    const buyWithBNB = async () => {
        let accounts = await ethereum.request({ method: "eth_requestAccounts" });
        var userAddr = accounts[0];
        console.log(userAddr);
        var chainId = await ethereum.request({ method: "eth_chainId" });
        console.log(chainId, 'chainid');

        if (chainId != 97) {
            console.log(chainId, 'chainid');
            alert("Please select Binance Smart Chain");
            return false;
        }
        var inputAmt = amount;
        inputAmt = parseFloat(inputAmt).toFixed(8);
        var weiAmt = 1000000000000000000 * inputAmt;
        var hexaDecimal = "0x" + weiAmt.toString(16);
        console.log(hexaDecimal);
        var Trade = new web3.eth.Contract(buy_abi, contractAddress);   

        // Buy the tokens
        const tx = await Trade.methods.buyToken().send({ from: userAddr, value: hexaDecimal }, function (err, result) {
            if (err !== null) {

            } else {
                var ethShowLink = "https://bscscan.com/tx/" + result;
                console.log("Success: ", ethShowLink);
            }
        });
        console.log(tx?.transactionHash, ':Transaction Hash');
        console.log(tx, ':tx');
    };
    return (
        <>
            <section class="  section-padding   login_sec">
                <div class="section-overlay"></div>

                <div class="container">
                    <div class="row">

                        <div class="col-lg-6 col-12 mx-auto">
                            <div class="page_title d-block text-center mb-5" >
                                <h2 class="" >  Claim Free <strong class="txt_gradient" > Airdrop  </strong></h2>
                                <p class="h6"> Enter detail to claim free z </p>
                            </div>
                            <form class="custom-form    card p-3 p-md-5 mb-5 mb-lg-0" >

                                <div class="card-body " >
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <label class="text-white mb-1 d-flex justify-content-between" ><span> Select Platform <em class="text-danger" > *</em></span>
                                                {/* <!-- <small><a href="#" class="text-white" >Need Help?</a></small> --> */}
                                            </label>
                                            <select class="form-control form-select" id="Platform">
                                                <option> Select Platform </option><option> Facebook </option>
                                                <option> Instagram </option><option> Telegram </option>
                                                <option> Twitter </option>
                                            </select>
                                             
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-12">
                                            <label class="text-white mb-1" > Username   <em class="text-danger" > *</em></label>
                                            <input type="text" name="form-Username" id="form-Username" class="form-control" placeholder="Enter Username" required  />
                                        </div> 


                                        <div class="col-lg-12 col-md-12 col-12">
                                            <label class="text-white mb-1" > Email   <em class="text-danger" > *</em></label>
                                            <input type="email" name="form-Username" id="form-Email" class="form-control" placeholder="Enter Email" required  />
                                        </div>  

                                        <div class="col-lg-12 col-md-12 col-12">
                                            <label class="text-white mb-1" > Phone Number   <em class="text-danger" > *</em></label>
                                            <input type="text" name="form-Number" id="form-Number" class="form-control" placeholder="Enter  Phone Number" required  />
                                        </div> 

                                        <div class="col-lg-12 col-md-12 col-12">
                                            <label class="text-white mb-1" > Recieving Wallet Address   <em class="text-danger" > *</em></label>
                                            <input type="text" name="form-Address" id="form-Address" class="form-control" placeholder="Enter Address" required  />
                                        </div> 

                                        <div class="col-lg-12 col-md-12 col-12">
                                            <label class="text-white mb-1" > Description   <em class="text-danger" > *</em></label>
                                            <textarea  class="form-control" rows="3" placeholder='Enter Description' > </textarea>
                                        </div> 

                                        <div class="col-lg-12 col-md-12 col-12">
                                            <label class="text-white mb-1" > Attachment   <em class="text-danger" > *</em></label>
                                            <input type="file" name="form-Attachment" id="form-Attachment" class="form-control ps-2" placeholder="Select File" required   />
                                        </div>  


                                    </div>

                                    <div class="col-lg-12 col-md-10 col-12 mx-auto">
                                        <button type="button" class=" btn custom-btn btn-gradient btn-border  btn-xl w-100 "> Submit </button>
                                    </div>


                                </div>



                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ClaimFree
