import React from "react"
import Loading from "./Components/Loading";
import LoaderHelper from "./Components/Loading/LoaderHelper";
import Routing from "./Routing/index";
import { connectorsForWallets, darkTheme, lightTheme, midnightTheme, RainbowKitProvider, } from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, useNetwork, useProvider, useSigner, WagmiConfig, } from "wagmi";
import { infuraProvider } from "wagmi/providers/infura";
import { injectedWallet, rainbowWallet, walletConnectWallet, metaMaskWallet, ledgerWallet, argentWallet, braveWallet, coinbaseWallet, imTokenWallet, trustWallet, omniWallet, } from "@rainbow-me/rainbowkit/wallets";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { useEffect } from "react";
import { ethers } from "ethers";



const bsc = {
  id: 56,
  name: "BSC",
  network: "BNB Smartchain",
  nativeCurrency: {
    decimals: 18,
    name: "BNB",
    symbol: "BNB",
  },
  rpcUrls: {
    default: "https://bsc-dataseed2.defibit.io",
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://bscscan.com" },
  },
  testnet: false,
};
const bscTestnet = {
  id: 97,
  name: "BSC Testnet",
  network: "BNB Smartchain Testnet",
  nativeCurrency: {
    decimals: 18,
    name: "BNB",
    symbol: "BNB",
  },
  rpcUrls: {
    default: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://testnet.bscscan.com" },
  },
  testnet: true,
};

const { chains, provider } = configureChains(
  [bscTestnet],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id !== bscTestnet.id) return null;
        return { http: chain.rpcUrls.default };
      },
    }),
  ]
);

// connect for wallets

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      injectedWallet({ chains }),
      rainbowWallet({ chains }),
      walletConnectWallet({ chains }),
      metaMaskWallet({ chains }),
      ledgerWallet({ chains }),
      argentWallet({ chains }),
      braveWallet({ chains }),
      coinbaseWallet({ chains }),
      imTokenWallet({ chains }),
      trustWallet({ chains }),
      omniWallet({ chains }),
    ],
  },
]);

const client = createClient({
  autoConnect: true,
  connectors,
  provider,
});

function App() {
  useEffect(() => {
    const connectWallet = async () => {
      // Check if Ethereum is available in the browser
      if (typeof window.ethereum !== 'undefined') {
        try {
          // Request access to the user's MetaMask wallet
          await window.ethereum.request({ method: 'eth_requestAccounts' });
  
          // Connect to the wallet using ethers
          const provider = new ethers.providers.Web3Provider(window.ethereum);
  
          // Get the user's address
          const signer = provider.getSigner();
          const address = await signer.getAddress();
  
          // Log the user's address
          console.log(`Connected to wallet at address ${address}`);
  
          // Adding custom token to MetaMask
          const tokenAddress = '0x646BD084E0c57F2FFF228a09360F837A4f22D007'; // Replace with the actual token address
          const tokenSymbol = 'METAi'; // Replace with the token symbol
          const tokenDecimals = 18; // Replace with the number of decimals for the token
  
          try {
            await window.ethereum.request({
              method: 'wallet_watchAsset',
              params: {
                type: 'ERC20',
                options: {
                  address: tokenAddress,
                  symbol: tokenSymbol,
                  decimals: tokenDecimals,
                },
              },
            });
            console.log('Token added to MetaMask wallet');
          } catch (error) {
            console.error('Error adding token to MetaMask wallet:', error);
          }
        } catch (error) {
          console.error('Error connecting to wallet:', error);
        }
      } else {
        // If Ethereum is not available, prompt the user to install MetaMask
        console.log('Please install MetaMask to use this dApp!');
      }
    };
  
    connectWallet();
  }, []);
  return (
    <WagmiConfig client={client}>
      <RainbowKitProvider     theme={midnightTheme({
         accentColorForeground: 'white',
         borderRadius:'small',
         fontStack: 'system',
         borderImageSlice:' 1 !important',

      })} chains={chains}>
        <Loading ref={ref => LoaderHelper.setLoader(ref)} />
        <Routing />
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
