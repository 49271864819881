import Swal from "sweetalert2";
// import '@sweetalert2/themes/wordpress-admin/wordpress-admin.css';
import '../../assets/@sweetalert2/themes/wordpress-admin/wordpress-admin.css';

function alertErrorMessage(message) {

  Swal.fire( message,"", 'error');
}

function alertSuccessMessage(message) {

  Swal.fire(message, '', 'success');
}
function alertWarningMessage(message) {

  Swal.fire(message, '', 'warning');
}

export { alertErrorMessage,alertSuccessMessage,alertWarningMessage };