import React, { useState } from "react"
import LandingPage from "../UI/LandingPage/index";
import BuyMeta from "../UI/BuyMeta/index";
import QuickBuy from "../UI/QuickBuy/index";



import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Footer from "../UI/Footer";
import Header from "../UI/Header";
import ContactUs from "../UI/ContactUs";
import ClaimFree from "../UI/ClaimFree";
const Routing = () => {
    const token = sessionStorage.getItem('token')

    return (
        <>
            <Router>
                <main>
                    <Header />
                    <Routes>

                        <Route exact path="/*" element={<LandingPage />} />
                        <Route exact path="/" element={<LandingPage />} />
                        <Route exact path="/BuyMeta" element={<BuyMeta />} />
                        <Route exact path="/QuickBuy" element={<QuickBuy />} />
                        <Route exact path="/ContactUs" element={<ContactUs />} />
                        <Route exact path="/ClaimFree" element={<ClaimFree />} />

                    </Routes>
                </main>
                <Footer />
            </Router>
        </>
    )
}
export default Routing