import React from 'react'

const Footer = () => {
  return (
<>


<footer className="site-footer">
                <div className="section-overlay"></div>

                <div className="container">
                    <div className="row gx-3">

                        <div className="col-lg-7 col-12 mb-4 pb-2">
                            <a className="navbar-brand" href="index.html">
                                <h2 className="txt_gradient mb-0" >Multiverse Meta</h2>
                            </a>
                            <p className="mt-2 mb-0" ><small>Multiversemeta.ai has the potential to revolutionize the way we interact with the digital world, and we are excited to embark on this journey with our partners and users. Together, we can create a more intelligent, immersive, and connected metaverse.</small></p>
                        </div>


                        <div className="col-lg-1 col-12 "></div>

                        {/* <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                            <h6 className="site-footer-title mb-3">About Us</h6>
                            <p className="text-white d-flex ">
                                <a href="cominsoon.html" className="site-footer-link">
                                    About MultiverseMeta
                                </a>
                            </p>
                            <p className="text-white d-flex">
                                <a href="cominsoon.html" className="site-footer-link">
                                    Contact Us
                                </a>
                            </p>
                            <p className="text-white d-flex">
                                <a href="cominsoon.html" className="site-footer-link">
                                    Whitepaper
                                </a>
                            </p>
                            <p className="text-white d-flex">
                                <a href="cominsoon.html" className="site-footer-link">
                                    Privacy Policy
                                </a>
                            </p>
                            <p className="text-white d-flex">
                                <a href="cominsoon.html" className="site-footer-link">
                                    Terms of Service
                                </a>
                            </p>
                        </div>

                        <div className="col-lg-2 col-md-6 col-12 mb-4 mb-lg-0">
                            <h6 className="site-footer-title mb-3">Services</h6>

                            <p className="text-white d-flex">
                                <a href="cominsoon.html" className="site-footer-link">
                                    Creators
                                </a>
                            </p>

                            <p className="text-white d-flex">
                                <a href="cominsoon.html" className="site-footer-link">
                                    Virtual Education
                                </a>
                            </p>

                            <p className="text-white d-flex">
                                <a href="cominsoon.html" className="site-footer-link">
                                    Gaming
                                </a>
                            </p>

                            <p className="text-white d-flex">
                                <a href="cominsoon.html" className="site-footer-link">
                                    Artwork
                                </a>
                            </p>

                            <p className="text-white d-flex">
                                <a href="cominsoon.html" className="site-footer-link">
                                    Healthcare
                                </a>
                            </p>
                        </div> */}

                        <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                            <h6 className="site-footer-title mb-3">Contact</h6>
                            <hr/>

                               
                            {/* <p className="text-white d-flex">
                                <a href="contact.html" className="site-footer-link">
                                    Contact Us
                                </a>
                            </p>  */}

                            <ul className="social-icon d-flex mb-3 ">
                                

                                <li className="social-icon-item">
                                    <a href="https://www.youtube.com/@MultiverseMeta" target='_blank' className="social-icon-link">
                                        <span className="ri-youtube-fill"> </span>
                                    </a>
                                </li>

                                <li className="social-icon-item">
                                    <a href="https://twitter.com/multiversemeta?s=21&t=P95KmVaga6ALlTnGhr_YmQ" 
                                    target='_blank' className="social-icon-link">
                                        <span className="ri-twitter-line"></span>
                                    </a>
                                </li>

                                <li className="social-icon-item">
                                    <a href="https://www.instagram.com/multiversemeta.ai/?igshid=YmMyMTA2M2Y%3D" 
                                    target='_blank' className="social-icon-link">
                                        <span className="ri-instagram-line"></span>
                                    </a>
                                </li>
                                <li className="social-icon-item">
                                    <a href="https://www.facebook.com/buymultiversemeta?mibextid=LQQJ4d" target='_blank' className="social-icon-link">
                                        <span className="ri-facebook-box-fill"></span>
                                    </a>
                                </li>
                            </ul>

                            <p className="text-white d-flex">
                                2023 © MultiverMeta.Ai All right reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>


        



            <div class="sky">
      <div class="stars"></div>
      {/* <!-- <div class="stars1"></div> --> */}
      {/* <!-- <div class="stars2"></div>  --> */}
      <div class="shooting-stars" ></div>
    </div>


</>
  )
}

export default Footer
