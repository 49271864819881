import React, { useEffect } from 'react'
import Header from '../Header'
import { useState } from 'react';
import { default as Web3 } from "web3";
import Footer from '../Footer';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';

const BuyMeta = () => {

    const [amount, setamount] = useState();
    const [token, settoken] = useState();


    useEffect(() => {
        window.scrollTo({ top: 20, behavior: 'smooth' });
        const links = document.querySelectorAll('.nav-link');
        links.forEach((link) => {
          link.classList.remove('active');
        })
      
    }, []);





  
    return (
        <>
            <section class="  section-padding   login_sec">
                <div class="section-overlay"></div>

                <div class="container">
                    <div class="row">

                        <div class="col-lg-6 col-12 mx-auto">
                            <div class="page_title d-block text-center mb-5" >
                                <h2 class="" >  Buy <strong class="txt_gradient" > METAi  </strong> Token</h2>
                                <p class="h6">Swap BNB</p>
                            </div>
                            <form class="custom-form    ticket-form mb-5 mb-lg-0" >

                                <div class="card-body " >
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <label class="text-white mb-1 d-flex justify-content-between" ><span> BNB <em class="text-danger" > *</em></span>
                                                {/* <!-- <small><a href="#" class="text-white" >Need Help?</a></small> --> */}
                                            </label>
                                            <input type="text" name="form-Amount" id="form-Amount" class="form-control" placeholder="Enter Amount" required onChange={(e) => { setamount(e.target.value) }} value={amount} />
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-12">
                                            <label class="text-white mb-1" > Token  <em class="text-danger" > *</em></label>
                                            <input type="text" name="form-Token" id="form-Token" class="form-control" placeholder="Enter Amount" required onChange={(e) => { settoken(e.target.value) }} value={token} />
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="price_bar" >
                                                Price:-$0.50
                                            </div>
                                        </div>


                                    </div>

                                    <div class="col-lg-12 col-md-10 col-12 mx-auto">
                                        <button type="button" class=" btn custom-btn btn-gradient btn-border  btn-xl w-100 "> Buy METAi </button>
                                    </div>


                                </div>



                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BuyMeta
