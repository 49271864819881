import { ApiConfig } from "../apiConfig/ApiConfig";
import { ApiCallPost } from "../apiConfig/ApiCall";
import { ApiCallGet } from "../apiConfig/ApiCall"

const AuthService = {

  wallet: async (formData) => {
    const { appUrl, wallet } = ApiConfig;
    const url = appUrl + wallet;
    const params = {

    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);
  },

  getbankdetails: async () => {
    const { appUrl, getbankdetails } = ApiConfig;
    const url = appUrl + getbankdetails;
    const params = {
      email: 'admin@multiversemeta.com'
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  getupidetails: async () => {
    const { appUrl, getupidetails } = ApiConfig;
    const url = appUrl + getupidetails;
    const params = {
      email: 'admin@multiversemeta.com'
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  ContactUs: async (inputData) => {
    const { appUrl, ContactUs } = ApiConfig;
    const url = appUrl + ContactUs;
    const params = {
      "name":inputData.Name,
      "phone":inputData.Mobile,
      "email":inputData.Email,
      "sub":inputData.Subject,
      "desc":inputData.Description
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },



};











export default AuthService;
