import React, { useEffect } from 'react'
import { useState } from 'react';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import { alertErrorMessage, alertWarningMessage } from '../../Components/CustomAlertMessage';
import AuthService from '../../api/services/HomeService';

const ContactUs = () => {
    const [inputData, setinputData] = useState({ });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setinputData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        
    };

const ContactUs = async (inputData) => {
    if(inputData.Name && inputData.Mobile && inputData.Email && inputData.Subject && inputData.Description ){
        LoaderHelper.loaderStatus(true);
        await AuthService.ContactUs(inputData).then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                } catch (error) {
                    console.log(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }else{
        alertWarningMessage("Please fill all mandatory fields")
    }

}
    useEffect(() => {
        window.scrollTo({ top: 20, behavior: 'smooth' });
    }, []);
    
    return (
    <>
            <section class="  section-padding   login_sec" id='contatcUs'>
                <div class="section-overlay"></div>

                <div class="container">
                    <div class="row">

                        <div class="col-lg-6 col-12 mx-auto">
                            <div class="page_title d-block text-center mb-5" >
                                <h2 class="" >  <strong class="txt_gradient" >  Contact Us </strong></h2>
                                <p class="h6">Enter details to contact with us</p>
                            </div>
                            <form class="custom-form    ticket-form mb-5 mb-lg-0" >

                                <div class="card-body " >
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <label class="text-white mb-1 d-flex justify-content-between" ><span> Enter Your Name <em class="text-danger">*</em> </span>
                                               
                                            </label>
                                            <input type="text" name="Name" id="Name" class="form-control" placeholder="Enter Full Name" required onChange={handleInputChange}  />
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-12">
                                            <label class="text-white mb-1" > Enter Your Email<em class="text-danger">*</em> </label>
                                            <input type="email" name="Email" id="form-email" class="form-control" placeholder="Enter Email Address" required  onChange={handleInputChange} />
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-12">
                                            <label class="text-white mb-1" > Enter Your Mobile No.<em class="text-danger">*</em></label>
                                            <input type="number" name="Mobile" id="form-Mobile" class="form-control" placeholder="Enter Mobile Number" required  onChange={handleInputChange} />
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-12">
                                            <label class="text-white mb-1" > Subject <em class="text-danger">*</em></label>
                                            <input type="text" name="Subject" id="form-Subject" class="form-control" placeholder="Enter Subject" required  onChange={handleInputChange} />
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-12">
                                            <label class="text-white mb-1" > Description <em class="text-danger">*</em></label>
                                            <textarea rows="4" class="form-control" name='Description' onChange={handleInputChange}/>
                                        </div>
                                        
                                    </div>
                                    <div class="col-lg-12 col-md-10 col-12 mx-auto">
                                        <button type="button" onClick={()=>{ContactUs(inputData)}}  class=" btn custom-btn btn-gradient btn-border  btn-xl w-100 "> Submit </button>
                                    </div>


                                </div>



                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}

export default ContactUs
