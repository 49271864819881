import React, { useEffect, useState } from 'react'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import '@rainbow-me/rainbowkit/styles.css';
import { Link, useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import { connect } from '@wagmi/core';


const Header = () => {
    const[address ,setaddress] = useState()
    const[connected  ,setconnected] = useState(false)
    useEffect(() => {
        const connectWallet = async () => {
          // Check if Ethereum is available in the browser
          if (typeof window.ethereum !== 'undefined') {
            try {
              // Request access to the user's MetaMask wallet
              await window.ethereum.request({ method: 'eth_requestAccounts' });
    
              // Connect to the wallet using ethers
              const provider = new ethers.providers.Web3Provider(window.ethereum);
    
              // Get the user's address
              const signer = provider.getSigner();
              const connectedAddress = await signer.getAddress();
    
              // Set the connected address
              setaddress(connectedAddress);
              setconnected(true)
            } catch (error) {
              console.error('Error connecting to wallet:', error);
            }
          } else {
            // If Ethereum is not available, prompt the user to install MetaMask
            console.log('Please install MetaMask to use this dApp!');
          }
        };
    
        connectWallet();
      }, []);
    
    const navigate = useNavigate()
    document.addEventListener('DOMContentLoaded', function () {
        const links = document.querySelectorAll('.nav-link');
        const navbarCollapse = document.querySelector('.navbar-collapse');
        const navbarToggler = document.querySelector('.navbar-toggler');
      
        links.forEach((link) => {
          link.addEventListener('click', (e) => {
            links.forEach((link) => {
              link.classList.remove('active');
            });
      
            if (navbarCollapse.classList.contains('show')) {
              navbarCollapse.classList.remove('show');
              navbarToggler.setAttribute('aria-expanded', 'false');
            }
      
            e.preventDefault();
            link.classList.add('active');
            const targetId = link.getAttribute('href').substring(1);
            const targetElement = document.querySelector(targetId);
      
            if (targetId === 'ContactUs') {
              navigate('/ContactUs');
            } else if (targetElement) {
              targetElement.scrollIntoView({
                behavior: 'smooth'
              });
            } else {
              navigate('/');
            }
          });
        });
      
        window.addEventListener('hashchange', function () {
          navbarCollapse.classList.remove('show');
          navbarToggler.setAttribute('aria-expanded', 'false');
        });
      });

    const addtoken =async () =>{
// Check if Web3 is already injected by the user's browser
if (typeof window.ethereum !== 'undefined') {
  // Use the injected Web3 provider
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  
  // Request access to the user's MetaMask wallet
  await window.ethereum.request({ method: 'eth_requestAccounts' });
  
  // Get the user's address
  const signer = provider.getSigner();
  const address = await signer.getAddress();

  // Log the user's address
  console.log(`Connected to wallet at address ${address}`);
  setaddress(address)
  setconnected(true)
  // Adding custom token to MetaMask
  const tokenAddress = '0x646BD084E0c57F2FFF228a09360F837A4f22D007'; // Replace with the actual token address
  const tokenSymbol = 'METAi'; // Replace with the token symbol
  const tokenDecimals = 18; // Replace with the number of decimals for the token

  try {
    await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
        },
      },
    });
    console.log('Token added to MetaMask wallet');
  } catch (error) {
    console.error('Error adding token to MetaMask wallet:', error);
  }
} else {
  // If Web3 is not injected, prompt the user to install MetaMask
  alert('Please install MetaMask to use this dApp!');
}
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src="images/logo.png" />
                    </Link>
                    <div className=" collapse navbar-collapse " id="navbarNav">
                        <ul className="navbar-nav align-items-lg-center ms-auto me-lg-3">
                            <li className="nav-item">
                                <a className="nav-link click-scroll " href="/#section_1">Home</a>
                            </li>

                            <li className="nav-item ">
                                <a className="nav-link click-scroll" href="/#aboutUs">About Us</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link click-scroll " href="/#roadmap">Roadmap</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link click-scroll" href="/#Tokenomics">Tokens</a>
                            </li>

                            <li className="nav-item activeNav">
                                <Link className="nav-link click-scroll " to="/ContactUs"> Contact us</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="nav_right" >
                        
                        {/* <a href="#"  className="btn custom-btn btn-gradient me-2   "> Buy Coin </a> */}
                        {/* <a href="/BuyMeta"  className="btn custom-btn btn-gradient me-2   "> Buy METAI  </a> */}
                        {/* <a href="/" className="btn custom-btn custom-border-btn  " onClick={() => WalletConnect()}>  Conntct Wallet </a> */}
                        <span className='btn custom-btn custom-border-btn wallet_btn' >
                        {connected ? (
       <p> {address.slice(0, 10)} </p>
      ) : (
        <button  className = "btn custom-btn  wallet_btn"onClick={() => window.ethereum.request({ method: 'eth_requestAccounts' })}>
          Connect Wallet
        </button>
      )}
                        </span>
                        <button className="navbar-toggler ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </div>
            </nav>


        </>
    )
}

export default Header
