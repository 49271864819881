import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'
import Header from '../Header';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { ethers } from 'ethers';

import { Autoplay } from "swiper";



const LandingPage = () => {
    const addtoken =async () =>{
        // Check if Web3 is already injected by the user's browser
        if (typeof window.ethereum !== 'undefined') {
          // Use the injected Web3 provider
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          
          // Request access to the user's MetaMask wallet
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          
          // Get the user's address
          const signer = provider.getSigner();
          const address = await signer.getAddress();
        
          // Log the user's address
          console.log(`Connected to wallet at address ${address}`);
        
          // Adding custom token to MetaMask
          const tokenAddress = '0x646BD084E0c57F2FFF228a09360F837A4f22D007'; // Replace with the actual token address
          const tokenSymbol = 'METAi'; // Replace with the token symbol
          const tokenDecimals = 18; // Replace with the number of decimals for the token
        
          try {
            await window.ethereum.request({
              method: 'wallet_watchAsset',
              params: {
                type: 'ERC20',
                options: {
                  address: tokenAddress,
                  symbol: tokenSymbol,
                  decimals: tokenDecimals,
                },
              },
            });
            console.log('Token added to MetaMask wallet');
          } catch (error) {
            console.error('Error adding token to MetaMask wallet:', error);
          }
        } else {
          // If Web3 is not injected, prompt the user to install MetaMask
          alert('Please install MetaMask to use this dApp!');
        }
            }
    return (
        <>
            <section className="hero-section" id="section_1">


                <div className="container d-flex justify-content-center align-items-center">
                    <div className="row align-items-center">

                        <div className=" col-lg-6 col-lg-6 ">

                            <h1 className="text-white mb-4">Explore the future of <br /> the  <em className="txt_gradient" >Multiverse Meta .ai</em> </h1>


                            
                            <img src="images/hero_img.png" className="img-fluid img-mobile" />



                            <p className="text-white" >
                                Our vision is to bridge the gap between the physical and digital worlds, creating a seamless and interconnected universe that is accessible to everyone.
                            </p>

                            <p className="text-white" >
                                Let's explore and create your experience with MMA.
                            </p>

                            <div className="d-flex align-items-center my-4 my-md-5" >
                            <a className="btn custom-btn  btn-xl btn-gradient me-2 me-md-3 btn-hover" href='https://bscscan.com/token/0x646bd084e0c57f2fff228a09360f837a4f22d007' >BSC Scan </a>
                            <button className="btn custom-btn  btn-xl btn-gradient me-2 me-md-3 btn-hover" onClick={() => addtoken()}  >Add Token to wallet</button>
                            </div>

                            {/* <div className="aac_sec d-flex align-items-start" >
                                <div className="aac_card" >
                                    <h4>29K+</h4>
                                    <span>Artworks</span>
                                </div>
                                <div className="aac_card" >
                                    <h4>18K+</h4>
                                    <span>Artists</span>
                                </div>
                                <div className="aac_card" >
                                    <h4>25K+</h4>
                                    <span>Creators</span>
                                </div>
                            </div> */}

                        </div>
                        <div className=" col-lg-6 col-lg-6 text-center ">
                            <img src="images/stars_img.png" className="img-fluid star_img" />
                            <img src="images/hero_img.png" className="img-fluid img-desktop" />
                        </div>


                    </div>

                </div>

            </section>



            <section className="about_sec section-padding" id='aboutUs' >
                <div className="container" >

                    <div className="row gx-5 align-items-center" >
                        <div className="col-lg-6" >
                            <img src="images/about_img.png" className="img-fluid" />

                        </div>
                        <div className="col-lg-6" >
                            <div className="section-title mb-0 text-start">
                                <h2 className="text-gradient">What is  <em className="txt_gradient" >Multiverse Meta</em></h2>
                                <p className="text-white"> With the power of AI, we can create intelligent virtual assistants that can help users navigate the metaverse, personalized experiences based on their preferences and behavior, and even predict user behavior to optimize their experience.</p>

                                <div className="d-flex align-items-center mt-4 mt-md-5" >
                                    <a className="btn custom-btn btn-hover  btn-xl btn-gradient me-2 me-md-3" href="register.html"> Metaverse + Artificial intelligence  </a>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </section>





            <section className="work-sec section-padding " >
                <div className="container" >
                    <div className="row justify-content-center" >
                        <div className="col-md-10 col-lg-8" >
                            <div className="section-title  text-center">
                                <h2 className="text-gradient">Where does the <em className="txt_gradient" >MultiverseMeta work</em></h2>
                                <p> Personalized experiences based on their preferences and behavior, and even predict user behavior to optimize their experience. The benefits of the metaverse and AI are numerous, and they include:</p>
                            </div>
                        </div>
                    </div>

                    <div className="row g-3 justify-content-center" >
                        <div className="col-lg-4" >
                            <div className="card card-solid" >
                                <div className="card-body" >
                                    <img src="images/work1.png" className="img-fluid w-100" />
                                    <div className="body-content" >
                                        <h6>Unlimited Possibility</h6>
                                        <p><small>With AI, we can create intelligent systems that can adapt and learn from user behavior, providing a truly personalized experience.</small></p>

                                        <div className="btn_row d-flex align-items-center" >
                                            {/* <Link to='/BuyMeta' className="btn custom-btn   btn-gradient me-2 me-md-3" >Try now</Link> */}
                                            {/* <a className="btn custom-btn   custom-border-btn" >Learn More</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-4" >
                            <div className="card card-solid" >
                                <div className="card-body" >
                                    <img src="images/work2.png" className="img-fluid w-100" />
                                    <div className="body-content" >
                                        <h6>Social Interaction</h6>
                                        <p><small>The metaverse provides a unique opportunity for social interaction, enabling users to connect with people from all over the world. </small></p>

                                        <div className="btn_row d-flex align-items-center" >
                                            {/* <Link to='/QuickBuy' className="btn custom-btn   btn-gradient me-2 me-md-3" >Try now</Link> */}
                                            {/* <a className="btn custom-btn   custom-border-btn" >Learn More</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-4" >
                            <div className="card card-solid" >
                                <div className="card-body" >
                                    <img src="images/work3.png" className="img-fluid w-100" />
                                    <div className="body-content" >
                                        <h6>Virtual Education & Training</h6>
                                        <p><small>AI-powered systems can help personalize these experiences to the user's needs, optimizing the learning process.</small></p>

                                        <div className="btn_row d-flex align-items-center" >
                                            {/* <Link to='/QuickBuy' className="btn custom-btn   btn-gradient me-2 me-md-3" >Try now</Link> */}
                                            {/* <a className="btn custom-btn   custom-border-btn" >Learn More</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-4" >
                            <div className="card card-solid" >
                                <div className="card-body" >
                                    <img src="images/work4.png" className="img-fluid w-100" />
                                    <div className="body-content" >
                                        <h6>Digital Commerce</h6>
                                        <p><small>The metaverse has the potential to revolutionize the way we do commerce, creating new business models and revenue streams. </small></p>

                                        <div className="btn_row d-flex align-items-center" >
                                            {/* <Link to='/QuickBuy' className="btn custom-btn   btn-gradient me-2 me-md-3" >Try now</Link> */}
                                            {/* <a className="btn custom-btn   custom-border-btn" >Learn More</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-4" >
                            <div className="card card-solid" >
                                <div className="card-body" >
                                    <img src="images/work5.png" className="img-fluid w-100" />
                                    <div className="body-content" >
                                        <h6>Healthcare</h6>
                                        <p><small>AI-powered systems can help facilitate these interactions, providing intelligent and personalised support to patients.</small></p>

                                        <div className="btn_row d-flex align-items-center" >
                                            {/* <Link to='/QuickBuy' className="btn custom-btn   btn-gradient me-2 me-md-3" >Try now</Link> */}
                                            {/* <a className="btn custom-btn   custom-border-btn" >Learn More</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>




            <section id='roadmap' className="timeline_section section-padding" >
                <div className="container-fluid" >
                    <div className="row justify-content-end" >
                        <div className="col-md-9 col-lg-9" >
                            <div className="section-title  text-center">
                                <h2 className="text-gradient"> <em className="txt_gradient" > Roadmap </em></h2>
                                <p className="text-white" >  This timeline details for our some key milestones <br /> that we are aiming to achieve in the near future</p>
                            </div>
                            <div className="timeline">
                                <div className="container_timeline left">
                                    <div className="content">
                                        <h6 className="" > <em className="txt_gradient" >Platform Development</em> </h6>
                                        <p className="" >
                                            We are currently in the process of developing the
                                            Multiversemeta.ai platform, which will include a
                                            virtual world, AI-powered systems, and
                                            tools for content creation and customization.
                                        </p>
                                    </div>
                                </div>

                                <div className="container_timeline right">
                                    <div className="content">
                                        <h6 className="" ><em className="txt_gradient" >Early Access Program</em></h6>
                                        <p>
                                            We will be launching an early access
                                            program for select users to test and
                                            provide feedback on our platform.
                                        </p>
                                    </div>
                                </div>

                                <div className="container_timeline left">
                                    <div className="content">
                                        <h6 className=" "><em className="txt_gradient" >Content Creation Tool</em></h6>
                                        <p className="">
                                            We will be developing a suite of tools for
                                            virtual content creation, including tools for
                                            3D modeling, animation, and audio production.
                                        </p>
                                    </div>
                                </div>

                                <div className="container_timeline right">
                                    <div className="content">
                                        <h6 className=""><em className="txt_gradient" >AI Powered Systems</em></h6>
                                        <p>
                                            We will be developing and implementing
                                            AI-powered systems, including virtual assistants,
                                            personalized experiences, and predictive analytics.
                                        </p>
                                    </div>
                                </div>

                                <div className="container_timeline left">
                                    <div className="content">
                                        <h6 className=""><em className="txt_gradient" >Partner Integration</em></h6>
                                        <p className="  ">
                                            We will be seeking partnerships with other
                                            platforms and services to integrate their
                                            offerings into the Multiversemeta.ai platform.
                                        </p>
                                    </div>
                                </div>

                                <div className="container_timeline right">
                                    <div className="content">
                                        <h6 className=""><em className="txt_gradient" >Launch & Marketing</em></h6>
                                        <p>
                                            We will be launching and marketing the
                                            Multiversemeta.ai platform to the wider public,
                                            showcasing the unique and innovative experiences
                                            that our platform can provide.
                                        </p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="features_sec section-padding" >
                <div className="container" >
                    <div className="row justify-content-center" >
                        <div className="col-md-10 col-lg-8" >
                            <div className="section-title  text-center">
                                <h2 className="text-gradient"> <em className="txt_gradient" >Collaboration Is Key</em>  </h2>
                                <p> We are actively seeking partnerships with like-minded individuals and organizations that share our passion for the metaverse and AI. Here are some potential collaborations that we believe would be a great fit for our platform</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center" >
                        <div className="col-lg-9 col-md-12" >
                            <div className="row g-4" >
                                <div className="col-lg-6" >
                                    <div className="card  h-100" >
                                        <div className="card-body" >
                                            <div className="how_card  " >
                                                <img src="images/ck1.svg"  height="100" className="img-fluid mb-2" />
                                                <div className="mt-4" >
                                                    <h6>
                                                        Virtual Content Creators
                                                    </h6>
                                                    <p className="my-3" >
                                                        <small>
                                                            We believe that content is king in the metaverse, and we are looking to collaborate with virtual content creators to bring their creations to life in our platform. This includes virtual artists, musicians, designers, and more.
                                                        </small>
                                                    </p>

                                                    {/* <a className="btn btn-link " >Read More </a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-6" >
                                    <div className="card" >
                                        <div className="card-body" >
                                            <div className="how_card  " >
                                                <img src="images/ck2.svg"   height="100" className="img-fluid mb-2" />
                                                <div className="mt-4" >
                                                    <h6>
                                                        AI Experts
                                                    </h6>
                                                    <p className="my-3" >
                                                        <small>
                                                            As an AI-powered platform, we are always looking for experts in the field of AI to collaborate with us on developing new and innovative AI-powered features and functionalities.
                                                        </small>
                                                    </p>

                                                    {/* <a className="btn btn-link " >Read More </a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-6" >
                                    <div className="card h-100" >
                                        <div className="card-body" >
                                            <div className="how_card  " >
                                                <img src="images/ck3.svg"   height="100" className="img-fluid mb-2" />
                                                <div className="mt-4" >
                                                    <h6>
                                                        Education & Training
                                                    </h6>
                                                    <p className="my-3" >
                                                        <small>
                                                            The metaverse has the potential to revolutionize the way we learn and train, and we believe that collaboration with education and training institutions can help us unlock this potential.
                                                        </small>
                                                    </p>

                                                    {/* <a className="btn btn-link " >Read More </a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-6" >
                                    <div className="card h-100" >
                                        <div className="card-body" >
                                            <div className="how_card  " >
                                                <img src="images/ck4.svg"   height="100" className="img-fluid mb-2" />
                                                <div className="mt-4" >
                                                    <h6>
                                                        Healthcare Providers
                                                    </h6>
                                                    <p className="my-3" >
                                                        <small>
                                                            The metaverse can also provide new and innovative ways for healthcare providers to deliver care to their patients. We believe that collaboration with healthcare providers can help us create new and personalized healthcare experiences for patients.
                                                        </small>
                                                    </p>

                                                    {/* <a className="btn btn-link " >Read More </a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>



            <section className="token_sec section-padding" id='Tokenomics'>
                <div className="container" >
                    <div className="row justify-content-center" >
                        <div className="col-md-10 col-lg-8" >
                            <div className="section-title  text-center">
                                <h2 className="text-gradient"> <em className="txt_gradient" >The Tokenomics</em>  </h2>
                                <p>  Our Token Distribution Is As Follows : </p>

                                <div className="d-flex btn_row align-items-center mt-4 justify-content-center" >
                                    <span className="btn custom-btn   btn-gradient me-2 me-md-3 px-4 btn-xl" ><strong>METAi</strong>  </span>
                                    <span className="btn custom-btn  custom-border-btn btn-xl d-flex align-items-center"   >Total Supply : &nbsp; <em className="txt_gradient fw-bold title_font" > 21 Million </em>  </span>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center g-4" >
                        <div className="col-lg-3 col-md-12 col-6" >
                            <div className="tt_card" >
                                <div className="how_card text-center  " >
                                    <img src="images/tenperesent.svg" className="img-fluid " />
                                    <div className="" >
                                        <p className=" mb-0 " >
                                            <strong>Private Sale    </strong>
                                        </p>
                                        <h4 className="txt_gradient fw-bold" >
                                            2.1 Million
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-6" >
                            <div className="tt_card" >
                                <div className="how_card text-center  " >
                                    <img src="images/tenperesent.svg" className="img-fluid " />
                                    <div className="" >
                                        <p className=" mb-0 " >
                                            <strong>  Team & Advisors </strong>
                                        </p>
                                        <h4 className="txt_gradient fw-bold" >
                                            2.1 Million
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-6" >
                            <div className="tt_card" >
                                <div className="how_card text-center  " >
                                    <img src="images/tenperesent.svg" className="img-fluid " />
                                    <div className="" >
                                        <p className=" mb-0 " >
                                            <strong>Community Development </strong>
                                        </p>
                                        <h4 className="txt_gradient fw-bold" >
                                            2.1 Million
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-6" >
                            <div className="tt_card" >
                                <div className="how_card text-center  " >
                                    <img src="images/fivetheenperesent.svg" className="img-fluid " />
                                    <div className="" >
                                        <p className=" mb-0 " >
                                            <strong>Ecosystem Incentives </strong>
                                        </p>
                                        <h4 className="txt_gradient fw-bold" >
                                            3.15 Million
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12  col-6" >
                            <div className="tt_card" >
                                <div className="how_card text-center  " >
                                    <img src="images/fivetheenperesent.svg" className="img-fluid " />
                                    <div className="" >
                                        <p className=" mb-0 " >
                                            <strong> Liquidity Pool </strong>
                                        </p>
                                        <h4 className="txt_gradient fw-bold" >
                                            3.15 Million
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12  col-6" >
                            <div className="tt_card" >
                                <div className="how_card text-center  " >
                                    <img src="images/twentypercent.svg" className="img-fluid " />
                                    <div className="" >
                                        <p className=" mb-0 " >
                                            <strong> Reserve Fund </strong>
                                        </p>
                                        <h4 className="txt_gradient fw-bold" >
                                            4.2 Million
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12  col-6" >
                            <div className="tt_card" >
                                <div className="how_card text-center  " >
                                    <img src="images/twentypercent.svg" className="img-fluid " />
                                    <div className="" >
                                        <p className=" mb-0 " >
                                            <strong>Public Sale  </strong>
                                        </p>
                                        <h4 className="txt_gradient fw-bold" >
                                            4.2 Million
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>

            <section className="token_sec section-padding" >
                <div className="container" >
                    <div className="row justify-content-center" >
                        <div className="col-md-10 col-lg-8" >
                            <div className="section-title  text-center">
                                <h2 className="text-gradient"> <em className="txt_gradient" >Private Allocation</em>  </h2>
                                <p>   The Private Allocation Will Be Distributed Is As Follows : </p>

                                <div className="d-flex btn_row align-items-center mt-4 justify-content-center" >
                                    <span className="btn custom-btn   btn-gradient me-2 me-md-3 px-4 btn-xl" ><strong>METAi</strong>  </span>
                                    <span className="btn custom-btn  custom-border-btn btn-xl d-flex align-items-center"   >Total Supply : &nbsp; <em className="txt_gradient fw-bold title_font" > 2.1 Million </em>  </span>
                                </div>
                                <br/>
                                <h6  > <small>Start From -</small> <span className='txt_gradient'>15 May 2023</span> </h6>



                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center g-4 mb-4" >
                        <div className="col-lg-3 col-md-12  col-6" >
                            <div className="tt_card" >
                                <div className="how_card text-center  " >
                                    <img src="images/thirtyperesent.svg" className="img-fluid " />
                                    <div className="" >
                                        <p className=" mb-0 " >
                                            <strong>Early Investors </strong>
                                        </p>
                                        <h4 className="txt_gradient fw-bold" >
                                            630 K
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12  col-6" >
                            <div className="tt_card" >
                                <div className="how_card text-center  " >
                                    <img src="images/thirtyperesent.svg" className="img-fluid " />
                                    <div className="" >
                                        <p className=" mb-0 " >
                                            <strong>  Strategic Partners </strong>
                                        </p>
                                        <h4 className="txt_gradient fw-bold" >
                                            630 K
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center g-4" >
                        <div className="col-lg-3 col-md-12  col-6" >
                            <div className="tt_card" >
                                <div className="how_card text-center  " >
                                    <img src="images/twentypercent2.svg" className="img-fluid " />
                                    <div className="" >
                                        <p className=" mb-0 " >
                                            <strong>Team & Advisors</strong>
                                        </p>
                                        <h4 className="txt_gradient fw-bold" >
                                            420 K
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12  col-6" >
                            <div className="tt_card" >
                                <div className="how_card text-center  " >
                                    <img src="images/twentypercent2.svg" className="img-fluid " />
                                    <div className="" >
                                        <p className=" mb-0 " >
                                            <strong>Reserve Fund </strong>
                                        </p>
                                        <h4 className="txt_gradient fw-bold" >
                                            420 K
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>







            <section className="ola_section  section-padding" id='resources' >
                <div className="container" >
                    <div className="row justify-content-center" >
                        <div className="col-md-10 col-lg-8" >
                            <div className="section-title  text-center">
                                <h2 className="text-gradient">Our Latest <em className="txt_gradient" >Activity</em></h2>
                                <p>  Adipiscing at in tellus integer. Pellentesque massa placerat duis ultricies. Venenatis cras </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container" >
                    {/* <div className="swiper videoSlide">
                            <div className="swiper-wrapper"> */}

                    <Swiper
                        className='videoSlide'
                        slidesPerView={"auto"}
                        centeredSlides={true}
                        spaceBetween={30}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                    >
                        <SwiperSlide> <div className="swiper-slide">
                            <div className="video_card">    <a className="play_icon" href="#"> <img src="images/play-icon.svg" className="img-fluid" /> </a>
                                <div className="slide_content" ><h3 className="mb-0" > Multiverse Meta Creators Week </h3></div>
                                <img src="images/video_1.jpg" className="img-fluid slide_img" />
                            </div>      </div></SwiperSlide>
                        <SwiperSlide> <div className="swiper-slide">
                            <div className="video_card">
                                <a className="play_icon" href="#"> <img src="images/play-icon.svg" className="img-fluid" /> </a>
                                <div className="slide_content" ><h3 className="mb-0" > Online Travel </h3></div>
                                <img src="images/video_2.jpg" className="img-fluid slide_img" />
                            </div>
                        </div></SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="video_card">
                                    <a className="play_icon" href="#"> <img src="images/play-icon.svg" className="img-fluid" /> </a>
                                    <div className="slide_content" ><h3 className="mb-0" > Multiverse Meta Creators Week </h3></div>
                                    <img src="images/video_1.jpg" className="img-fluid slide_img" />
                                </div>
                            </div></SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="video_card">
                                    <a className="play_icon" href="#"> <img src="images/play-icon.svg" className="img-fluid" /> </a>
                                    <div className="slide_content" ><h3 className="mb-0" > Online Travel </h3></div>
                                    <img src="images/video_2.jpg" className="img-fluid slide_img" />
                                </div>
                            </div></SwiperSlide>

                    </Swiper>



                    {/* </div>
                            <div className="swiper-pagination"></div>
                        </div> */}
                </div>


            </section>





            <section className="mge_section  section-padding" >
                <div className="container" >
                    <div className="row justify-content-center" >
                        <div className="col-md-10 col-lg-8" >
                            <div className="section-title  text-center">
                                <h2 className="text-gradient"><em className="txt_gradient" >MultiverseMeta Global Expansion</em></h2>
                                <p>  Our platform provides a unique opportunity for individuals and businesses globally to explore and innovate in the metaverse, harnessing the power of AI to create intelligent and personalized experiences. </p>
                            </div>
                        </div>
                    </div>
                    <img src="images/map_img.png" className="w-100 img-fluid" />
                </div>


            </section>


            <div className="section-title  text-center mb-0 mb-md-3">
                <h2 className="text-gradient"><em className="txt_gradient" >Upcoming Partnerships</em></h2>
            </div> 
            <section className="part_sec section-padding"> 
                            
                <div className="container-fluid" >
                    <div className="parter_row" >
                        <img src="images/p1.png" />
                        <img src="images/p2.png" />
                        <img src="images/p3.png" />
                        <img src="images/p4.png" />
                        <img src="images/p5.png" />
                        <img src="images/p6.png" />
                        <img src="images/p7.png" />
                        <img src="images/p8.png" />
                        <img src="images/p9.png" />
                        <img src="images/p10.png" />
                        <img src="images/p11.png" />
                    </div>

                </div>
            </section>



            {/* <section className="faq_sec section-padding" >
                <div className="container" >
                    <div className="row justify-content-center" >
                        <div className="col-md-10 col-lg-8" >
                            <div className="section-title  text-center">
                                <h2 className="text-gradient"><em className="txt_gradient" >FAQs</em></h2>
                            </div>
                        </div>
                    </div>


                    <div id="accordionFlushExample" className="accordion accordion-flush">
                        <div className="row">

                            <div className="col-lg-6">
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="feed_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#feed_onea" aria-expanded="false" aria-controls="feed_onea">
                                            <span>How can I purchase tokens?</span>
                                            <i className="ri-arrow-down-s-line"></i>
                                        </button>
                                    </h5>
                                    <div id="feed_onea" className="accordion-collapse collapse" aria-labelledby="feed_one" data-bs-parent="#accordionFlushExample"  >

                                        <div className="acc_body" >
                                            Buy from MMA DeFi Composite Index. DeFi Composite Index, the first such index derivatives product by Binance. Use Fiat Deposit to add cash balance of your local currency into your Binance account.
                                        </div>

                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="two_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#two_onea" aria-expanded="false" aria-controls="feed_onea">
                                            <span>What is a Multiverse Meta Token (MMA) ?</span>
                                            <i className="ri-arrow-down-s-line"></i>
                                        </button>
                                    </h5>
                                    <div id="two_onea" className="accordion-collapse collapse" aria-labelledby="two_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >
                                        Multiversemeta.ai has the potential to revolutionize the way we interact with the digital world, and we are excited to embark on this journey with our partners and users. Together, we can create a more intelligent, immersive, and connected metaverse.
                                         </div>

                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="three_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#three_onea" aria-expanded="false" aria-controls="feed_onea">
                                            <span>When and how shall I receive purchased tokens?</span>
                                            <i className="ri-arrow-down-s-line"></i>
                                        </button>
                                    </h5>
                                    <div id="three_onea" className="accordion-collapse collapse" aria-labelledby="three_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >
                                            Buy from MMA DeFi Composite Index. DeFi Composite Index, the first such index derivatives product by Binance. Use Fiat Deposit to add cash balance of your local currency into your Binance account.
                                        </div>

                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="four_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#four_onea" aria-expanded="false" aria-controls="feed_onea">
                                            <span>What is the minimum token purchase amount?</span>
                                            <i className="ri-arrow-down-s-line"></i>
                                        </button>
                                    </h5>
                                    <div id="four_onea" className="accordion-collapse collapse" aria-labelledby="four_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >
                                            Buy from MMA DeFi Composite Index. DeFi Composite Index, the first such index derivatives product by Binance. Use Fiat Deposit to add cash balance of your local currency into your Binance account.
                                        </div>

                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="five_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#five_onea" aria-expanded="false" aria-controls="feed_onea">
                                            <span>What interest rate will the platform provide?</span>
                                            <i className="ri-arrow-down-s-line"></i>
                                        </button>
                                    </h5>
                                    <div id="five_onea" className="accordion-collapse collapse" aria-labelledby="five_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >
                                            Buy from MMA DeFi Composite Index. DeFi Composite Index, the first such index derivatives product by Binance. Use Fiat Deposit to add cash balance of your local currency into your Binance account.
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">

                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="six_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#six_onea" aria-expanded="false" aria-controls="six_onea">
                                            <span>What are the benefits of buying MMA coins?</span>
                                            <i className="ri-arrow-down-s-line"></i>
                                        </button>
                                    </h5>
                                    <div id="six_onea" className="accordion-collapse collapse" aria-labelledby="six_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >
                                            Buy from MMA DeFi Composite Index. DeFi Composite Index, the first such index derivatives product by Binance. Use Fiat Deposit to add cash balance of your local currency into your Binance account.
                                        </div>

                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="seven_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#seven_onea" aria-expanded="false" aria-controls="seven_onea">
                                            <span>When and on which exchanges will the MMA token be listed?</span>
                                            <i className="ri-arrow-down-s-line"></i>
                                        </button>
                                    </h5>
                                    <div id="seven_onea" className="accordion-collapse collapse" aria-labelledby="seven_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >
                                            Buy from MMA DeFi Composite Index. DeFi Composite Index, the first such index derivatives product by Binance. Use Fiat Deposit to add cash balance of your local currency into your Binance account.
                                        </div>

                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="eight_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#eight_onea" aria-expanded="false" aria-controls="eight_onea">
                                            <span>Can I purchase tokens using a credit card?</span>
                                            <i className="ri-arrow-down-s-line"></i>
                                        </button>
                                    </h5>
                                    <div id="eight_onea" className="accordion-collapse collapse" aria-labelledby="eight_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >
                                            Buy from MMA DeFi Composite Index. DeFi Composite Index, the first such index derivatives product by Binance. Use Fiat Deposit to add cash balance of your local currency into your Binance account.
                                        </div>

                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="nine_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#nine_onea" aria-expanded="false" aria-controls="nine_onea">
                                            <span>What wallet do I need to store MMA coins?</span>
                                            <i className="ri-arrow-down-s-line"></i>
                                        </button>
                                    </h5>
                                    <div id="nine_onea" className="accordion-collapse collapse" aria-labelledby="nine_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >
                                            Buy from MMA DeFi Composite Index. DeFi Composite Index, the first such index derivatives product by Binance. Use Fiat Deposit to add cash balance of your local currency into your Binance account.
                                        </div>

                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="ten_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ten_onea" aria-expanded="false" aria-controls="ten_onea">
                                            <span>Are there any extra bonuses for large investors?</span>
                                            <i className="ri-arrow-down-s-line"></i>
                                        </button>
                                    </h5>
                                    <div id="ten_onea" className="accordion-collapse collapse" aria-labelledby="ten_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >
                                            Buy from MMA DeFi Composite Index. DeFi Composite Index, the first such index derivatives product by Binance. Use Fiat Deposit to add cash balance of your local currency into your Binance account.
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section> */    }



        </>
    )
}

export default LandingPage
