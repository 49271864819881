import React, { createContext, useEffect, useState } from 'react'

export const context = createContext();

const ContextProvider = ({ children }) => {

    return (
        <> 
          
        <context.Provider value={{ }}> {children}</context.Provider>
        </>
    )
}

export default ContextProvider;
