const appUrl = "http://3.94.196.205:3003";

export const ApiConfig = {
 wallet:'suraksha/userorder',
 getbankdetails: 'suraksha/getbankdetails',
 getupidetails: 'suraksha/getupidetails',
 ContactUs: 'suraksha/ContactUs',



  appUrl: `${appUrl}/`,
};
